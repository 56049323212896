import { defineStore } from 'pinia'

export const useMessageCenter = defineStore('messageCenter', {
	state: () => ({
		totalCount: 0,
		deepLinkMessage: '',
		selectedMessageDetails: {
			selectedMember: null,
			message: null
		}
	}),
	getters: {
		messageCenter() {
			return this.$state
		}
	},
	actions: {
		updateMessageCenter(payload) {
			Object.keys(payload).forEach((key) => {
				if (Object.keys(this.$state).includes(key)) {
					this.$state[key] = payload[key]
				}
			})
		},
		clearSelectedMessageDetails() {
			this.selectedMessageDetails = {
				selectedMember: null,
				message: null
			}
		},
		updateSelectedMessageDetails(selectedMember, message) {
			this.selectedMessageDetails = {
				selectedMember: selectedMember,
				message: message
			}
		}
	},
	persist: {
		storage: sessionStorage
	}
})
